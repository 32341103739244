export const errorPage: any = {
    patMainMenu: "Main Navigational Menu Consumer GCSO - Global",
    patFooterMenu: "Footer Navigation Menu Consumer GCSO - Global",
    patFooter: "Retina Global Footer Consumer - US - Global",
    exitPopup: "Retina Canada Exit Popup New - Global",
    hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
    patTopNav: "Top Navigational Menu Consumer US - Global",
    patSiteLogo: "Site logos - Global",
    patSitemapLabel: "Retina DTC Sitemap - Full HTML - Global",
    banner: "Banner Error page - Global",
    subhead: "Subhead error page - Global",
    footerClassName: "common-footer error-footer",
    bodyClassName: "error-page",
    redirectLangUrl: {
      redirectUrl: {
        "en": "/error",
        "es": "/es/error",
      }
    }
  }