import React from 'react'
import { Layout, HTMLRenderer } from "@retina-packages/retina-theme-bootstrap"
import { graphql } from 'gatsby'
import { ErrorStyle, SitemapWrapper } from '../templates/global/error/styles'
import { PageWrapper } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { Props, PageQueryResult, GeneralTagType } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import ArticleSiteMapContent from '@retina-packages/retina-theme-bootstrap/packages/containers/ArticleContainer/sitemap';
import retinaConfig from '../utils/retinaConfigs';
import { errorPage } from '../templates/global/error/constants';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../utils';
import { HomePageFooterContainer } from '../css/common/style'
import { MenuResultType } from "@retina-packages/retina-theme-bootstrap/packages/types/MenuType"

type ErrPageQueryResult = PageQueryResult & {
  nodePage: {
    field_meta_tags: any
    relationships: {
      field_related_menus: MenuResultType[]
      field_general_tags_gcso: GeneralTagType[]
      field_paragraphs_gcso: any
    }
  }
}
type ErrorPageData = Props & {
  data: {
    nodePage: ErrPageQueryResult['nodePage']
    nonSVGImages: any
    svgMediaImages: any
    allMediaDocument: any
    allMediaImage: {
      edges: any
    }
  }
}

const ErrorPage = (props: ErrorPageData) => {
  const pgProp: ErrPageQueryResult = props.data
  const metaInfo = pgProp.nodePage.field_meta_tags
  metaInfo.bodyClassAttributes = errorPage.bodyClassName
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const htmlStaticFilesErr = {
    nonSVGImages: props.data.nonSVGImages,
    svgMediaImages: props.data.svgMediaImages,
    allMediaDocument: props.data.allMediaDocument
  }

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const topMenuLabel = errorPage.patMainMenu
  const foorMenuLabel = errorPage.patFooterMenu
  const footerTitle = errorPage.patFooter
  const topNavTitle = errorPage.patTopNav

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: topMenuLabel, siteFooterMenu: foorMenuLabel });

  const bannerTitle = ""
  const carouselTitle = ""
  const exitPopupTitle = errorPage.exitPopup
  const hcpSwitcherTitle = errorPage.hcpSwitcher
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  const bannerHTML = fullHTMLDataBuilder({blocks, title: errorPage.banner})
  const subHeadText = fullHTMLDataBuilder({blocks, title: errorPage.subhead})
  const siteMapFullHTMLArt = fullHTMLDataBuilder({blocks, title: errorPage.patSitemapLabel})
  const siteLogos = deriveSiteLogo({ blocks, title: errorPage.patSiteLogo })
  const pagewrapper = "sitemap"

  return (
    <SitemapWrapper className="sitemapwrapper">
      <HomePageFooterContainer className="privacy-policy-footer-wrapper homepagefootercontainer">
        <ErrorStyle className="error-style">
          {metaInfo !== null && (
              <MetaTagContainer metaData={metaInfo} />
            )}
          <Layout
            title={"siteTitle"}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            staticMediaFiles={htmlStaticFilesErr}
            backToTopContent={retinaConfig.backToTopContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={errorPage.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            languageConfig={{... retinaConfig.langConfig}}
            {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
            <PageWrapper className={`pagewrapper ${pagewrapper}`}>
              <MobileBanner className="mobile-banner clinical-trials-banner common-banner mobilebanner">
                <HTMLRenderer data={htmlStaticFilesErr} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <HTMLRenderer data={htmlStaticFilesErr} html={subHeadText} tagName='div' className='error-banner-title' />
              <ArticleSiteMapContent
                audience={audience}
                location={props.location}
                siteMapContent={siteMapFullHTMLArt}
                hcplinks={hcpSwitcher}
                hcpHomeUrl={retinaConfig.hcpHomeUrl}
              />
            </PageWrapper>
          </Layout>
        </ErrorStyle>
      </HomePageFooterContainer>
  </SitemapWrapper>
  )
}

export default ErrorPage

// Page query in GraphQL
export const pageQuery = graphql`
  query {
    nodePage(field_url_alias: {eq: "/error"}) {
      field_meta_tags {
        title
        description
        og_type
        og_url
        og_title
        og_image_url
        og_description
        canonical_url
        twitter_cards_type
        twitter_cards_description
        twitter_cards_image
        twitter_cards_page_url
        twitter_cards_title
        hreflang_per_language_hreflang_en_us
        hreflang_per_language_hreflang_en_ca
        hreflang_per_language_hreflang_fr_ca
        hreflang_per_language_hreflang_pt_br
      }
      relationships {
        ...ErrorPageQuery
      }
    }
    nonSVGImages: allMediaImage(
      filter: {relationships: {field_media_image: {localFile: {ext: {ne: ".svg"}}}}}
    ) {
      edges {
        node {
          drupal_id
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
          field_media_image {
            alt
            title
          }
        }
      }
    }
    svgMediaImages: allMediaImage(
      filter: {relationships: {field_media_image: {localFile: {ext: {eq: ".svg"}}}}}
    ) {
      edges {
        node {
          drupal_id
          relationships {
            field_media_image {
              localFile {
                publicURL
              }
            }
          }
          field_media_image {
            alt
            title
          }
        }
      }
    }
    allMediaDocument {
      edges {
        node {
          drupal_id
          relationships {
            field_media_document {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
